<template>
  <b-card
    v-if="data"
    class="card-tiny-line-stats"
    body-class="pb-50"
  >

    <h6>Kapal</h6>
    <b-avatar
      variant="light-success"
      size="45"
    >
      <feather-icon
        size="21"
        icon="AnchorIcon"
      />
    </b-avatar>
    <!-- chart -->
    <vue-apex-charts
      height="210"
      :options="statisticsProfit.chartOptions"
      :series="data.series"
    />
    <b-card-text class="font-small-2 mr-25 mb-0">
      <strong>Updated this today for 7 days ago</strong>
    </b-card-text>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    statisticsRiverItems: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      statisticsProfit: {
        chartOptions: {
          chart: {
            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.info, $themeColors.danger, $themeColors.primary],
          markers: {
            size: 2,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.info,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            // categories: [
            //   'Senin, 25 Jul',
            //   'Selasa, 26 Jul',
            //   'Rabu, 27 Jul',
            //   'Kamis, 28 Jul',
            //   'Jumat, 29 Jul',
            //   'Sabtu, 30 Jul',
            // ],
            categories: this.data?.series[0]?.categories ?? ['Senin, 25 Juli', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
}
</script>
