<template>
  <b-card title="Analisa Jumlah Kendaraan">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="mb-1 mb-sm-0">
        <span class="text-muted">
          Jumlah Kendaraan <code>{{ name }}</code>
          <strong> {{ isPrevious ? showPrevToday : showToday }}</strong
          >.
        </span>
      </div>
      <div class="d-flex align-content-center mb-1 mb-sm-0">
        <small class="pt-75">
          <b-badge :variant="'warning'">
            <feather-icon icon="TruckIcon" />
            ∑ Total Kemarin ( {{ prevTodayTotalValue ?? 0 }} )
          </b-badge>
          &nbsp;
          <b-badge :variant="'info'">
            <feather-icon icon="TruckIcon" />
            ∑ Total Hari Ini ( {{ todayTotalValue ?? 0 }} )
          </b-badge>
        </small>
        <div class="ml-75">
          <b-badge variant="light-secondary">
            <feather-icon
              :icon="isUP ? 'ArrowUpIcon' : 'ArrowDownIcon'"
              size="12"
              :class="`text-${isUP ? 'success' : 'danger'} align-middle`"
            />
            <span class="font-weight-bolder align-middle">
              {{ percent }}%
            </span>
          </b-badge>
        </div>
      </div>
    </div>
    <b-button-group>
      <b-button
        class="mr-1"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="handlePrevClick"
      >
        <feather-icon icon="ArrowLeftCircleIcon" />
        <span> Previous</span>
      </b-button>

      <b-button
        class="mr-1"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="handleExport"
      >
        <feather-icon icon="FileTextIcon" />
        <span> Tarik data Excel</span>
      </b-button>

      <b-button
        class="mr-1"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="handleNextClick"
      >
        <feather-icon icon="ArrowRightCircleIcon" />
        <span> Next</span>
      </b-button>
    </b-button-group>
    <app-echart-line :option-data="optionData" :name="name" />

    <!-- echart -->
  </b-card>
</template>

<script>
import { BCard, BBadge, BButtonGroup, BButton } from 'bootstrap-vue'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    AppEchartLine,
    BBadge,
    BButton,
    BButtonGroup
  },
  directives: {
    Ripple
  },
  props: {
    groupid: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    todayTotalValue: {
      type: null,
      default: null
    },
    prevTodayTotalValue: {
      type: null,
      default: null
    },
    isPrevious: {
      type: Boolean,
      default: false
    },
    optionData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showToday: '',
      showPrevToday: '',
      year: '',
      percent: 0,
      isUP: false
    }
  },
  created() {
    this.getCurrentYear()
    this.getCurrentMonth()
    this.getPreviousMonth()

    const oldValue = this.prevTodayTotalValue
    const todayValue = this.todayTotalValue

    const percentageChange =
      ((todayValue - oldValue) / Math.abs(oldValue)) * 100
    const adjustedPercentageChange = Math.min(percentageChange, 100) // Cap the percentage change at 100%
    const isNegativeChange = percentageChange < 0

    this.percent = adjustedPercentageChange.toFixed(2)
    this.isUP = !isNegativeChange
  },
  methods: {
    getCurrentYear() {
      const currentDate = moment()
      this.year = currentDate.format('YYYY') // Four-digit year, e.g., "2023"
    },
    getCurrentMonth() {
      const currentDate = moment()
      this.showToday = `${currentDate.format('MMMM')} ${this.year}` // Full month name, e.g., "February"
    },
    getPreviousMonth() {
      const previousDate = moment().subtract(1, 'month')
      this.showPrevToday = `${previousDate.format('MMMM')} ${this.year}`
    },
    handleNextClick() {
      // Trigger the custom event with a payload
      this.$emit('custom-event', {
        name: this.name,
        group_id: this.group_id,
        state: false // 'next'
      })
    },
    handleExport() {
      // Trigger the custom event with a payload
      this.$emit('custom-event', {
        name: `${this.name}_EXCEL`,
        group_id: this.group_id
        // state: false // 'next'
      })
    },
    handlePrevClick() {
      // Trigger the custom event with a payload
      this.$emit('custom-event', {
        name: this.name,
        group_id: this.group_id,
        state: true // 'previous'
      })
    }
  }
}
</script>
