var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "dashboard-landgraph"
    }
  }, [_c('b-img', {
    staticStyle: {
      "position": "absolute",
      "right": "0",
      "opacity": "10%",
      "top": "0"
    },
    attrs: {
      "fluid": "",
      "src": _vm.imgUrl,
      "alt": "Reporting River"
    }
  }), _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "4",
      "md": "12",
      "cols": "12"
    }
  }, [_c('statistic-card-with-line-chart', {
    key: _vm.componentKey.statistic,
    attrs: {
      "icon": "TruckIcon",
      "color": "danger",
      "statistic": _vm.rtTruckTodayALL,
      "statistic-title": "",
      "chart-data": _vm.data.statisticsOrder.series
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8",
      "md": "12",
      "sm": "12",
      "cols": "12"
    }
  }, [_c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3",
      "cols": "6"
    }
  }, [_c('statistic-card-vertical', {
    attrs: {
      "hide-chart": "",
      "color": "danger",
      "icon": "TruckIcon",
      "statistic": _vm.rtTruckTodayPatraCount,
      "loading": _vm.rtTruckTodayLoading,
      "statistic-title": "PATRA",
      "disable-view-limit": _vm.disableViewLimit,
      "max-approvement": _vm.maxApprovement['PATRA'],
      "current-approvement": _vm.currentApprovement['PATRA']
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3",
      "cols": "6"
    }
  }, [_c('statistic-card-vertical', {
    attrs: {
      "hide-chart": "",
      "color": "danger",
      "icon": "TruckIcon",
      "statistic": _vm.rtTruckTodayTemCount,
      "loading": _vm.rtTruckTodayLoading,
      "statistic-title": "TEM",
      "disable-view-limit": _vm.disableViewLimit,
      "max-approvement": _vm.maxApprovement['TEM'],
      "current-approvement": _vm.currentApprovement['TEM']
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3",
      "cols": "6"
    }
  }, [_c('statistic-card-vertical', {
    attrs: {
      "hide-chart": "",
      "color": "danger",
      "icon": "TruckIcon",
      "statistic": _vm.rtTruckTodayPilCount,
      "loading": _vm.rtTruckTodayLoading,
      "statistic-title": "PIL",
      "disable-view-limit": _vm.disableViewLimit,
      "max-approvement": _vm.maxApprovement['PIL'],
      "current-approvement": _vm.currentApprovement['PIL']
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3",
      "cols": "6"
    }
  }, [_c('statistic-card-vertical', {
    attrs: {
      "hide-chart": "",
      "color": "success",
      "icon": "TruckIcon",
      "statistic": _vm.rtTruckTodayHuluCount,
      "loading": _vm.rtTruckTodayLoading,
      "statistic-title": "HULU",
      "disable-view-limit": _vm.disableViewLimit,
      "max-approvement": _vm.maxApprovement['HULU'],
      "current-approvement": _vm.currentApprovement['HULU']
    }
  })], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "4",
      "md": "4",
      "cols": "12"
    }
  }, [_vm.data.congratulations ? _c('b-card', {
    staticClass: "card-congratulation-medal"
  }, [_vm.data.congratulations.isHost === true ? _c('div', [_c('v-select', {
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.ignoreGroupData,
      "reduce": function reduce(option) {
        return option.value;
      },
      "selectable": function selectable(option) {
        return !option.value.includes('null');
      },
      "label": "text",
      "item-value": "value",
      "item-text": "text"
    },
    model: {
      value: _vm.hostCondition.ignoreGroup,
      callback: function callback($$v) {
        _vm.$set(_vm.hostCondition, "ignoreGroup", $$v);
      },
      expression: "hostCondition.ignoreGroup"
    }
  }), _c('hr'), _vm.hostCondition.ignoreGroup !== 'true' ? _c('v-select', {
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.loadTypeFormData,
      "reduce": function reduce(option) {
        return option.value;
      },
      "selectable": function selectable(option) {
        return !option.value.includes('null');
      },
      "label": "text",
      "placeholder": "Select Form Type",
      "item-value": "value",
      "item-text": "text"
    },
    model: {
      value: _vm.hostCondition.loadTypeForm,
      callback: function callback($$v) {
        _vm.$set(_vm.hostCondition, "loadTypeForm", $$v);
      },
      expression: "hostCondition.loadTypeForm"
    }
  }) : _vm._e(), _c('hr'), _vm.hostCondition.ignoreGroup !== 'true' && _vm.hostCondition.loadTypeForm ? _c('vue-autosuggest', {
    attrs: {
      "id": "vi-group-name",
      "host-condition-group-account-": "",
      "suggestions": [{
        data: _vm.groupAccountData
      }],
      "get-suggestion-value": _vm.getSuggestionValue,
      "limit": 7,
      "input-props": {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: 'Group Account'
      }
    },
    on: {
      "input": function input($event) {
        return _vm.fetchGroupData($event, 'group/search/');
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var suggestion = _ref.suggestion;
        return [_c('span', {
          staticClass: "my-suggestion-item"
        }, [_vm._v(_vm._s(suggestion.item.name))])];
      }
    }], null, false, 3574080467)
  }) : _vm._e()], 1) : _c('div', [_c('h5', [_vm._v(" " + _vm._s(_vm.$can('manage', 'qrcode') === true ? 'Welcome' : 'Congratulations') + " 🎉 " + _vm._s(_vm.data.congratulations.name) + "! ")]), _c('b-card-text', {
    staticClass: "font-small-3"
  }, [_vm._v(" " + _vm._s(_vm.$can('manage', 'qrcode') === true ? '-' : 'You have make a form in and out.') + " ")]), _c('h3', {
    staticClass: "mb-75 mt-2 pt-50"
  }, [_c('b-link', [_vm._v("Keep on safety first")])], 1), _c('b-img', {
    staticClass: "congratulation-medal",
    attrs: {
      "src": require('@/assets/images/illustration/badge.svg'),
      "alt": "Medal Pic"
    }
  })], 1)]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "lg": "8",
      "md": "8",
      "cols": "12"
    }
  }, [_c('landgraph-order-chart', {
    key: _vm.componentKey.truck,
    attrs: {
      "data": _vm.data.statisticsOrder,
      "statistics-land-items": _vm.data.statisticsLandItems
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('landgraph-kendaraan-Index')], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('overlay-loading', {
    attrs: {
      "loading": _vm.loading
    }
  }), _c('landgraph-revenue-report', {
    key: _vm.componentKey.qrcode,
    attrs: {
      "data": _vm.data.revenue
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }