<template>
  <e-charts
    ref="line"
    autoresize
    :options="line"
    theme="theme-color"
    @click="onChartClick"
    auto-resize
  />
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/toolbox' // Import toolbox component
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import theme from './theme.json'
ECharts.registerTheme('theme-color', theme)
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    ECharts
  },
  props: {
    optionData: {
      type: Object,
      default: null
    },
    name: {
      type: String,
      default: null
    }
  },
  setup() {
    // defined
    const { dailyVehicle } = useAppConfig()

    return {
      dailyVehicle
    }
  },
  data() {
    return {
      line: {
        animationDuration: 1000,
        title: [
          {
            left: 'center',
            text: 'Gradient along the y axis'
          }
        ],
        // Make gradient line here
        visualMap: [
          {
            show: true,
            type: 'continuous',
            min: 0,
            max: 400
          }
        ],
        grid: {
          width: '96%',
          left: '30px',
          top: '10px',
          show: true
        },
        tooltip: {
          trigger: 'axis',
          formatter: this.customTooltip
        },
        xAxis: [
          {
            boundaryGap: true,
            data: this.optionData.xAxisData,
            triggerEvent: true,
            axisLabel: { rotate: 50, interval: 0 }
          }
        ],
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              // title: 'Data View', // Customize the tooltip title
              yAxisIndex: 'none'
            },
            dataView: {
              title: 'Data View', // Customize the tooltip title
              readOnly: true
            },
            // magicType: { type: ['line', 'bar'] },
            restore: {
              title: 'Restore View' // Customize the tooltip title
            },
            // saveAsImage: {}
            saveAsImage: {
              show: true, // Enable export to image functionality
              title: 'Save as Image', // Customize the tooltip title
              name: `Jumlah Kendaraan`,
              backgroundColor: 'transparent',
              pixelRatio: 2
            }
          }
        },
        language: 'en',
        yAxis: {
          type: 'value',
          splitLine: { show: false }
        },
        series: {
          type: 'line',
          showSymbol: true,
          data: this.optionData.series
        }
      }
    }
  },
  methods: {
    customTooltip(params) {
      // Use params to access data for the hovered point
      const dataIndex = params[0].dataIndex
      const value = params[0].value
      const xAxisData = this.optionData.xAxisData[dataIndex] // Get the corresponding xAxisData

      // Custom tooltip content
      return [
        `∑ Jumlah <strong>${value}</strong>`,
        `📅 Clock IN Time <strong>${xAxisData}</strong>`
      ].join('<br>')
    },
    onChartClick(params) {
      // Check if the click target is series symbol
      if (params.componentType === 'series') {
        this.dailyVehicle = {
          date: params.name,
          endpoint: this.name
        }
      }
    }
  },
  watch: {
    // Watch for changes in the optionData prop
    optionData: {
      deep: true,
      handler(newData) {
        // Update the chart's data whenever the optionData prop changes
        this.line.xAxis.data = newData.xAxisData
        this.line.series.data = newData.series
      }
    }
  }
}
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
