<template>
  <b-card
    v-if="data"
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="8"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            <b-avatar
              variant="light-danger"
              size="45"
            >
              <feather-icon
                size="21"
                icon="TruckIcon"
              />
            </b-avatar>
            QRCode Report
          </h4>
          <div class="d-flex align-items-center">

            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
              <span>Un Available</span>
            </div>

            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-success svg-font-small-3 mr-50 cursor-pointer" />
              <span>Available</span>
            </div>

            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-info svg-font-small-3 mr-50 cursor-pointer" />
              <span>Used</span>
            </div>

            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-danger svg-font-small-3 mr-50 cursor-pointer" />
              <span>Expired</span>
            </div>

          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          type="bar"
          height="230"
          :options="revenueReport.chartOptions"
          :series="data.revenueReport.series"
        />
      </b-col>

      <b-col
        md="4"
        class="budget-wrapper"
      >
        <br>
        <br>
        <h2 class="mb-25">
          Used: {{ sumChart(2) }}
        </h2>
        <div class="d-flex justify-content-center">
          <span class="font-weight-bolder mr-25">Available:</span>
          <span>{{ sumChart(1) }}</span>
        </div>
        <div class="d-flex justify-content-center">
          <span class="font-weight-bolder mr-25">Un Available:</span>
          <span> {{ sumChart(0) }}</span>
        </div>
        <div class="d-flex justify-content-center">
          <span class="font-weight-bolder mr-25">Expired:</span>
          <span> {{ sumChart(3) }}</span>
        </div>
        <vue-apex-charts
          id="budget-chart"
          type="line"
          height="80"
          :options="budgetChart.options"
          :series="data.budgetChart.series"
        />

        <!-- <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
        >
          View Data
        </b-button> -->
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      revenue_report: {},
      revenueReport: {
        chartOptions: {
          chart: {
            stacked: false,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: true },
            },
          },
          xaxis: {
            // categories: ['Senin, 31 Jul', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
            categories: this.data?.categories ?? ['Senin, 25 Juli', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
            labels: {
              style: {
                colors: '#FFD95E5A',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.warning, $themeColors.success, $themeColors.info, $themeColors.danger],
          plotOptions: {
            bar: {
              columnWidth: '40%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: 'success',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
          },
        },
      },
      // budget chart
      budgetChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            sparkline: { enabled: true },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [15, 10, 5],
            width: [2, 2, 4],
          },
          colors: [$themeColors.warning, $themeColors.success, $themeColors.info, $themeColors.danger],
          tooltip: {
            enabled: true,
          },
        },
      },
    }
  },
  methods: {
    sumChart(index) {
      return this.data.budgetChart.series[index].data.reduce((a, b) => a + b, 0)
    },
  },
}
</script>
