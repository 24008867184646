var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.data ? _c('b-card', {
    attrs: {
      "body-class": "pb-50"
    }
  }, [_c('h6', [_vm._v("Truck Statistics")]), _c('b-row', _vm._l(_vm.statisticsLandItems, function (item, index) {
    return _c('b-col', {
      key: index,
      class: item.customClass,
      attrs: {
        "xl": "3",
        "sm": "5"
      }
    }, [index >= 4 ? _c('hr') : _vm._e(), _c('b-media', {
      attrs: {
        "no-body": ""
      }
    }, [_c('b-media-aside', {
      staticClass: "mr-2"
    }, [_c('b-avatar', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover",
        value: item.popContent,
        expression: "item.popContent",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "size": "42",
        "variant": item.color,
        "title": item.popTitle
      }
    }, [_c('feather-icon', {
      attrs: {
        "size": "18",
        "icon": item.icon
      }
    })], 1)], 1), _c('b-media-body', {
      staticClass: "my-auto"
    }, [_c('h4', {
      staticClass: "font-weight-bolder mb-0"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('b-card-text', {
      staticClass: "font-small-3 mb-0"
    }, [_vm._v(" " + _vm._s(item.subtitle) + " ")])], 1)], 1)], 1);
  }), 1), _c('vue-apex-charts', {
    attrs: {
      "height": "100",
      "options": _vm.statisticsOrder.chartOptions,
      "series": _vm.data.series
    }
  }), _c('b-card-text', {
    staticClass: "font-small-2 mr-25 mb-0"
  }, [_c('strong', [_vm._v("Updated this today for 7 days ago ( Data 7 Hari Terakhir ) ")])])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }