var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.data ? _c('b-card', {
    staticClass: "card-tiny-line-stats",
    attrs: {
      "body-class": "pb-50"
    }
  }, [_c('h6', [_vm._v("Kapal")]), _c('b-avatar', {
    attrs: {
      "variant": "light-success",
      "size": "45"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "21",
      "icon": "AnchorIcon"
    }
  })], 1), _c('vue-apex-charts', {
    attrs: {
      "height": "210",
      "options": _vm.statisticsProfit.chartOptions,
      "series": _vm.data.series
    }
  }), _c('b-card-text', {
    staticClass: "font-small-2 mr-25 mb-0"
  }, [_c('strong', [_vm._v("Updated this today for 7 days ago")])])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }