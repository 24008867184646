<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        <strong>Updated this today for 7 days ago</strong>
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <br v-if="$can('manage', 'all') === true" />
      <b-row>
        <b-col
          v-for="(item, index) in data"
          :key="item.icon"
          xl="3"
          sm="5"
          :class="item.customClass"
        >
          <hr v-if="index >= 4" />
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                v-b-popover.hover="item.popContent"
                size="42"
                :variant="item.color"
                :title="item.popTitle"
              >
                <feather-icon size="18" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  VBPopover,
  BPopover,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    VBPopover,
    BPopover,
    BMediaAside,
    BMediaBody
  },
  directives: {
    'b-popover': VBPopover,
    Ripple
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  created() {
    console.log('created statistic')
  }
}
</script>
