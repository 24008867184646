var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "title": "Analisa Data Perjam ( ".concat(_vm.todayValue != '' ? _vm.todayValue : 'Daily', " )")
    }
  }, [_c('app-echart-line', {
    attrs: {
      "option-data": _vm.optionData,
      "name": _vm.name
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-between flex-wrap"
  }, [_c('div', {
    staticClass: "mb-1 mb-sm-0"
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v("Jumlah Berdasarkan")]), _vm._v("  "), _c('b-badge', {
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Clock IN Time ")])], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }