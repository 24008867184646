<template>
  <b-card v-if="data" body-class="pb-50">
    <h6>Truck Statistics</h6>
    <b-row>
      <b-col
        v-for="(item, index) in statisticsLandItems"
        :key="index"
        xl="3"
        sm="5"
        :class="item.customClass"
      >
        <hr v-if="index >= 4" />
        <b-media no-body>
          <b-media-aside class="mr-2">
            <b-avatar
              v-b-popover.hover="item.popContent"
              size="42"
              :variant="item.color"
              :title="item.popTitle"
            >
              <feather-icon size="18" :icon="item.icon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0">
              {{ item.title }}
            </h4>
            <b-card-text class="font-small-3 mb-0">
              {{ item.subtitle }}
            </b-card-text>
          </b-media-body>
        </b-media>
      </b-col>
    </b-row>

    <vue-apex-charts
      height="100"
      :options="statisticsOrder.chartOptions"
      :series="data.series"
    />
    <b-card-text class="font-small-2 mr-25 mb-0">
      <strong
        >Updated this today for 7 days ago ( Data 7 Hari Terakhir )
      </strong>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  VBPopover,
  BPopover,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'

const $barColor = '#f3f3f3'

export default {
  components: {
    VBPopover,
    BPopover,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    VueApexCharts
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    statisticsLandItems: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      statisticsOrder: {
        chartOptions: {
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false
            }
          },
          grid: {
            show: true,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '20%',
              startingShape: 'rounded',
              colors: {
                backgroundBarColors: [
                  $barColor,
                  $barColor,
                  $barColor,
                  $barColor,
                  $barColor
                ],
                backgroundBarRadius: 5
              }
            }
          },
          legend: {
            show: false
          },
          dataLabels: {
            enabled: false
          },
          colors: [$themeColors.info],
          xaxis: {
            categories: this.data?.series[0]?.categories ?? [
              'Senin, 25 Juli',
              'Selasa',
              'Rabu',
              'Kamis',
              'Jumat',
              'Sabtu'
            ],
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }
          },
          yaxis: {
            show: false
          },
          tooltip: {
            x: {
              show: true
            }
          }
        }
      }
    }
  },
  created() {
    console.log('created truck analytic')
  }
}
</script>
