<template>
  <b-card
    :title="`Analisa Data Perjam ( ${
      todayValue != '' ? todayValue : 'Daily'
    } )`"
  >
    <!-- echart -->
    <app-echart-line :option-data="optionData" :name="name" />
    <div class="d-flex justify-content-between flex-wrap">
      <div class="mb-1 mb-sm-0">
        <span class="text-muted">
          <strong>Jumlah Berdasarkan</strong>
          &nbsp;<b-badge variant="primary"> Clock IN Time </b-badge>
        </span>
        <!-- <br />
      <small>Jumlah berdasarkan Clock IN Time pada nilai HH ( Hours ).</small>
      <br />
      <small>Contoh 20:23 , 20:30 (HH:mm), (HH:mm) = ∑ 2 Kendaraan </small>  -->
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BBadge } from 'bootstrap-vue'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'

export default {
  components: {
    BCard,
    AppEchartLine,
    BBadge
  },
  props: {
    groupid: {
      type: String,
      default: null
    },
    todayValue: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    optionData: {
      type: Object,
      default: null
    }
  }
  // watch: {
  //   // Watch for changes in the optionData prop
  //   optionData: {
  //     deep: true,
  //     handler(newData) {
  //       console.log('new data::', newData)
  //       // Update the chart's data whenever the optionData prop changes
  //       this.optionData.xAxis = newData.xAxisData
  //       this.optionData.series = newData.series
  //     }
  //   },
  //   todayValue: {
  //     deep: true,
  //     handler(newData) {
  //       console.log('new data todayValue::', newData)
  //     }
  //   }
  // }
}
</script>
